<template>
    <div>
        <v-row>
            <v-col lg="6">
                <s-crud
                title="Precintos"
                add
                edit
                remove
                :filter="filter"
                :config="config"
                
                @save="save($event)"
                >

                <template v-slot:filter>
					<v-container>
						<v-row style="margin-left: 5px" justify="center">
                            <v-col cols="12" lg="4" md="4">
                            <s-select-definition clearable label="Color" :def="1337" v-model="filter.TypeColor"></s-select-definition>
                            </v-col>

                            <v-col cols="12" lg="4" md="4">
                                <s-select-definition clearable label="Tipo" :def="1338" v-model="filter.TypeSeal"></s-select-definition>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                <template scope="props">
                    <v-container v-if="props.item != null" class="pb-0">
                        <v-row justify="center">
                            <v-col cols="12" lg="4" md="4">
                                <s-text label="Número Precinto" v-model="props.item.SalNumber" ></s-text>
                            </v-col>
                            <v-col cols="12" lg="4" md="4">
                                <s-select-definition label="Color" :def="1337" v-model="props.item.TypeColor"></s-select-definition>
                            </v-col>

                            <v-col cols="12" lg="4" md="4">
                                <s-select-definition label="Tipo" :def="1338" v-model="props.item.TypeSeal"></s-select-definition>
                            </v-col>
                        </v-row>

                    </v-container>
                </template>

                <template v-slot:TypeColorName="{ row }">
					<v-chip x-small :color="row.TypeColorHexadecimal">
						{{ row.TypeColorName }}
					</v-chip>
				</template>
                </s-crud>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import _sSeal from "@/services/ExternalTrade/ExtSealService";
export default {

    data:()=> ({
        filter:{},
        config:{
            title:"",
            model:{
                SalID: "ID",
                TypeColorName: ""
            },
            service: _sSeal,    
            headers:[
                {text: "ID", value:"SalID"},
                {text:"Número Precinto", value:"SalNumber"},
                {text:"Color", value:"TypeColorName"},
                {text:"Color (Hexadecimal)", value:"TypeColorHexadecimal"},
                {text:"Tipo Precinto", value:"TypeSealName"}
            ],
        }
    }),
    methods:{

        save(item){
            if(item.SalNumber.length == 0){
                this.$fun.alert("Ingrese numero de precinto", "warning")
                return
            }

             if(item.TypeColor == null){
                this.$fun.alert("Ingrese color", "warning")
                return
            }

             if(item.TypeSeal == null){
                this.$fun.alert("Ingrese tipo precinto", "warning")
                return
            }
            item.SecStatus = 1,
            item.UsrCreateID = this.$fun.getUserID(),
            console.log(item);
            item.save();
        }
    },
}
</script>
