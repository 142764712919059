import Service from "../Service";
import Vue from "vue";
const resource = "seal/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },
    save(parameters, requestID){
        return Service.post(resource + "save", parameters,{
            params:{requestID:requestID},
        });
    },

    GetUrlSeal(){   
     
        return Vue.prototype.$http.defaults.baseURL + "/seal/getSealNumber"; 
         },

    getSealNumber(requestID, SalNumber) {
        return Service.post(resource + "getSealNumber", {}, {
            params: { requestID: requestID, SalNumber: SalNumber },
        });
    },
}
